<template>
  <section>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!--   1   -->
      <div v-for="(val, key) in pdfs" :key="key" class="col-span-6 sm:col-span-12  xl:col-span-4 intro-y card-content" >
        <Card class="m-3 box cursor-pointer zoom-in" @click.stop="DownloadEstado('ver', val.file)">
          <template #header>
              <img alt="user header" src="/img/banner-file.jpg" class="img-file">
          </template>
          <template #title>
            <p> {{val.name}}</p>
          </template>
          <!-- <template #content>
          </template> -->
          <template #footer>
              <!-- <Button icon="pi pi-check" label="Descargar" @click.stop="DownloadEstado" /> -->
              <a class="btn-rounded-primary px-4 py-1" @click.stop="" :href="DownloadEstado('down', val.file)" download="download">Descargar</a>
          </template>
        </Card>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { helper } from '../../../../utils/helper'

// import can from './can'
// import dayjs from 'dayjs'

export default defineComponent({
  components: {

  },
  setup (props, { emits }) {
    // const estadoBtn = ref(2)

    const pdfs = [
      { name: 'Estados Financieros 2022', file: 'CapitalState-EF2022' }
    ]

    const DownloadEstado = (tipo, name) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API}`
      const ruta = '/pdf/' + name + '.pdf'
      if (tipo === 'ver') {
        window.open(url + ruta, '_blank')
      } else { return url + ruta }
    }

    return {
      helper,
      pdfs,
      DownloadEstado
      // can
    }
  }
})

</script>
<style lang="scss" scoped>

.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.btn-atras{
  padding-top: 4px;
  padding-bottom: 4px;
}
.color-azul{
  color: #1c3faa;
}
.btn-change{
  position: absolute;
  right: 5px;
  top: 5px;
}
.img-file{
      height: 48px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}
@media(max-width: 600px){
  .card-content{
    zoom: 0.6;
  }
}
</style>
